.my-masonry-grid {
  display: flex;
  margin-left: -1px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 1px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 1px;
}