@import './masonry-grid.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap%27');

@layer utilities {
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }

  .PopoverContent {
    /* max-width: calc(var(--radix-popover-content-available-width) - 16psx); */
    /* margin-left: 8px;
    margin-right: 8px; */
    max-height: calc(var(--radix-popover-content-available-height) - 16px);
  }
  .DropdownMenuContent {
    max-height: var(--radix-dropdown-menu-content-available-height);
  }
}
 
@layer base {
  a {
    @apply ring-offset-background transition-colors focus-visible:rounded-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2;
  }
  
  html {
    -webkit-tap-highlight-color: transparent;
    /* overflow-y: scroll; */
  }

  body {
    @apply bg-muted text-foreground font-sans antialiased text-pretty;
    font-feature-settings: 'rlig' 1, 'calt' 1;
    /* overflow: hidden; */
  }

  #__next {
    @apply h-full;
    /* overflow-y: scroll; */
  }

  .sketch-picker {
    color: white !important;
  }
  .sketch-picker input {
    background-color: rgba(255, 255, 255, 0.1) !important;
    border-color: #222 !important;
    box-shadow: none !important;
    outline-color: #434343;
    color: #fff;
  }
  .sketch-picker label {
    color: #fff !important;
  }

  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --accent: 217.2 91.2% 59.8%;
    --accent-foreground: 0 0% 98%;
/* 
    --accent: 223.3 100% 50%; updated light
    --accent: 204 100% 50%; updated dark   */


    --destructive: 0 72.22% 50.59%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5% 64.9%;
    --radius: 0.5rem;

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;

    --success: 114 57% 50%;
    --success-foreground: 0 0% 98%;

    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 217.2 91.2% 59.8%;
    --accent-foreground: 0 0% 98%;
    
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;

    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;

    --success: 114 57% 50%;
    --success-foreground: 0 0% 98%;

    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
}

/* Headings */
h1 {
  @apply scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl;
}

h2 {
  @apply scroll-m-20 text-3xl font-semibold tracking-tight transition-colors first:mt-0;
}

h3 {
  @apply scroll-m-20 text-2xl font-semibold tracking-tight;
}

h4 {
  @apply scroll-m-20 text-xl font-semibold tracking-tight;
}

h5 {
  @apply scroll-m-20 text-lg font-semibold tracking-tight;
}

h6 {
  @apply scroll-m-20 text-base font-semibold tracking-tight;
}

/* Paragraph */
p {
  @apply leading-7 [&:not(:first-child)]:mt-0;
}

/* Blockquote */
blockquote {
  @apply mt-6 border-l-2 pl-6 italic;
}

/* Lists */
/* ul, ol {
  @apply my-6 ml-6 list-disc [&>li]:mt-2;
} */

/* Inline Code */
code {
  @apply relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold;
}

pre {
  @apply mb-4 mt-6 overflow-x-auto rounded-lg border bg-black py-4;
}

pre code {
  @apply relative !m-0 overflow-x-auto p-0 font-mono text-sm;
}

pre code:before {
  @apply absolute left-2 top-2 h-5 w-5 text-slate-300;
}

pre code:after {
  @apply absolute right-2 top-2 h-5 w-5 text-slate-300;
}

/* Lead Paragraph */
.lead {
  @apply text-xl text-muted-foreground;
}

/* Large Text */
.large {
  @apply text-lg font-semibold;
}

/* Small Text */
.small {
  @apply text-sm font-medium leading-none;
}

/* Muted Text */
.muted {
  @apply text-sm text-muted-foreground;
}